import * as React from "react";

function createMarkup(code) {
    return {__html: code};
  }
  

const Form = () => {
    return (
        <div dangerouslySetInnerHTML={createMarkup(`
            <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
            <div id="mc_embed_signup">
                <form action="https://ticon.us14.list-manage.com/subscribe/post?u=4112e92cf0404ba435a410927&amp;id=d7e9762358" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                    <div class="indicates-required"><span class="asterisk">*</span> påkrevd</div>
                    <div class="mc-field-group">
                        <label for="mce-EMAIL">e-postadresse  <span class="asterisk">*</span>
                    </label>
                    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE1">Navn </label>
                    <input type="text" value="" name="MMERGE1" class="" id="mce-MMERGE1">
                </div>
                <div class="mc-field-group">
                    <label for="mce-MMERGE2">Telefon </label>
                    <input type="text" value="" name="MMERGE2" class="" id="mce-MMERGE2">
                </div>
                <div class="mc-field-group input-group">
                    <strong>Megler </strong>
                    <ul><li><input type="radio" value="Jeg ønsker kontakt med megler" name="MMERGE3" id="mce-MMERGE3-0"><label for="mce-MMERGE3-0">Jeg ønsker kontakt med megler</label></li>
                </ul>
                </div>
                    <div class="mc-field-group input-group">
                        <strong>Ønsker kontakt </strong>
                        <ul><li><input type="checkbox" value="1" name="group[1635][1]" id="mce-group[1635]-1635-0"><label for="mce-group[1635]-1635-0">Jeg ønsker kontakt med megler</label></li></ul>
                    </div>
                    <div id="mce-responses" class="clear">
                        <div class="response" id="mce-error-response" style="display:none"></div>
                        <div class="response" id="mce-success-response" style="display:none"></div>
                    </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_4112e92cf0404ba435a410927_d7e9762358" tabindex="-1" value=""></div>
                        <div class="clear"><input type="submit" value="Meld på" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                    </div>
                </form>
            </div>
            <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='MMERGE1';ftypes[1]='text';fnames[4]='MMERGE2';ftypes[4]='text';fnames[3]='MMERGE3';ftypes[3]='radio';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
`)}/>
    )
}

export default Form;