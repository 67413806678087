import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from '../components/PageWrapper';
import images from '../images';
import Form from '../components/form';
import Img from "gatsby-image";

const IndexPage = ({data}) => {
  return (
    <PageWrapper>
		<Helmet>
	       	<meta charSet="utf-8" />
          	<title>Konnerud Senter Panorama</title>
		  	<meta name="description" content="Nye moderne leiligheter med god standard, terrasse og utsikt ¬– midt i sentrum på Konnerud." />
          	<link rel="canonical" href="https://www.konnerudsenterpanorama.no" />
			<link rel="icon" type="image/png" href={images.favicon} sizes="16x16" />

		  	<meta property="og:title" content="Konnerud Senter Panorama" />
			<meta property="og:description" content="Nye moderne leiligheter med god standard, terrasse og utsikt ¬– midt i sentrum på Konnerud." />
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://www.konnerudsenterpanorama.no" />
			<meta property="og:image" content={images.ogImage} />

        </Helmet>

		<Img
	        fluid={data.headerImage.childImageSharp.fluid}
    	    alt="Konnerud Senter Panorama"
      	/>
		<div className="section dark">
			<div className="container">
				<h1>Stedet der vi vil bo!</h1>
				<p>
					Vi er mange som har et hjerte som anker for Konnerud. Stedet vårt som folk vil flytte til, men som ingen vil flytte fra. 
					Derfor er vi stolte av å presentere <strong>Konnerud Senter Panorama*</strong>, en ny unik mulighet for deg som ønsker å bo i en moderne leilighet med god standard, 
					romslig terrasse og god utsikt ­– midt i sentrum på Konnerud.
				</p>
				<p className="small"><em>* Prosjektet gikk tidligere under navnet 222 Konnerud</em></p>
			</div>
		</div>

		<Img
			fluid={data.illustration01.childImageSharp.fluid}
			alt="Illustrasjon 1"
		/>

		<div className="section">
			<div className="container">
				<h1>Vidsyn over Konnerud</h1>
				<p>
					<strong>Konnerud Senter Panorama</strong> består av 75 leiligheter fordelt på 3 hus rett ved siden av Konnerud Senter. 
					Alle leilighetene har gode verandaer med flott utsyn utover landskapet. Du kan velg mellom mange ulike størrelser, 
					fra ca. 40–160 m2. Den gode variasjonen i leilighetstyper, gjør det enkelt å finne en leilighet som passer akkurat for deg og dine behov. 
				</p>
			</div>
		</div>

		<div className="dark">
			<div className="half">
				<div className="col-6 text-pad">
					<h1>Gjennomført kvalitet i alle rom</h1>
					<p>
						På <strong>Konnerud Senter Panorama</strong>, skal det føles trygt og godt å flytte inn i en splitter ny og moderne leilighet. 
						Her er alt designet for at du skal trives og føle deg hjemme fra første stund. Alle leilighetene er utformet og bygget av fagfolk med øye for detaljer. 
						I alt fra materialbruk til planløsninger oser det av kvalitet, slik at ditt nye drømmehjem får alle de kvalitetene du er på jakt etter.
					</p>
				</div>
				<div className="col-6">
					<div className="cover-image" style={{ backgroundImage: `url(${images.jeanPhilippeDelberghe})`}}/>
				</div>
			</div>
		</div>

		<div className="image-row-container">
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow1})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow2})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow3})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow4})`}}/>
			</div>
		</div>

		<div className="section">
			<div className="container">
				<h2>“Konnerud Senter Panorama - umiddelbar nærhet til både shopping, treningssenter og skogen.”</h2>
			</div>
		</div>

		<Img
			fluid={data.illustration02.childImageSharp.fluid}
			alt="Illustrasjon 2"
		/>

		<div className="section dark">
			<div className="container">
				<h1>Tilrettelagt for en aktiv livsstil</h1>
				<p>
					Fra <strong>Konnerud Senter Panorama</strong> har du tilgang til alt det vi setter så stor pris på med Konnerud. 
					Her har du naturen, idrettsmulighetene, det sosiale livet og det gode servicetilbudet tett på. 
					Samtidig slipper du å tenke på vedlikehold og snømåking. Det gir deg bedre tid til å prioritere dine interesser. 
					I skogen er det milevis med ski- og turløyper samt deilige badevann. På senteret har du et stort servicetilbud og gode 
					møteplasser som du kan gå tørrskodd til fra leiligheten – året rundt. Ønsker du å bruke det rike tilbudet i Drammen, 
					er det bare å hente ut bilen i garasjeanlegget, eller å hoppe på en av de mange bussene som frakter deg til og fra sentrum.
				</p>
			</div>
		</div>

		<div className="image-row-container">
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow5})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow6})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow7})`}}/>
			</div>
			<div className="col-3">
				<div className="cover-image" style={{ backgroundImage: `url(${images.imgRow8})`}}/>
			</div>
		</div>

		<div className="section dark">
			<div className="container">
				<h1>Sett deg på interessentlisten allerede nå!</h1>
				<p>Få beskjed om nyheter og være først i køen når det nærmer seg salgstart. Meld deg på interesselisten vår og få tilsendt prospekt pr. e-post.</p>
				<p>
					<strong>Øystein Liverud</strong><br/>
					Meglerhuset & Partners Drammen<br/>
					ol@partners.no<br/>
					(+47) 918 84 174<br/>
				</p>
			</div>
		</div>
				
		<div className="section">
			<div className="container">
				<Form/>
			</div>
		</div>

		<div className="section dark">
			<div className="container">
				<p><strong>Samtykke</strong></p>
				<p>Jeg godkjenner at dere kan lagre min kontaktinformasjon for e-postutsendelse av nyhetsbrev og annen relevant prosjektinformasjon fra megler og utbygger.</p>
				<p>Meglerhuset & Partners Drammen vil bruke informasjonen du oppgir på dette skjemaet til markedsføring, være i kontakt med deg og gi oppdateringer. Du kan når som helst forandre dine valg ved å klikke på abonnementslinken i bunnen av e-post du mottar dra oss.</p>
			</div>
		</div>

		


    </PageWrapper>
  )
}

export default IndexPage

export const headerImage = graphql`
  query imageQuery {
    headerImage: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality:95,maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
	}
	illustration01: file(relativePath: { eq: "illustration-01.jpg" }) {
		childImageSharp {
		  fluid(quality:95,maxWidth: 1600) {
			...GatsbyImageSharpFluid
		  }
		}
	}
	illustration02: file(relativePath: { eq: "illustration-02.jpg" }) {
		childImageSharp {
		  fluid(quality:95,maxWidth: 1600) {
			...GatsbyImageSharpFluid
		  }
		}
	}
  }
`